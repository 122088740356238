.content {
    margin-bottom: 1em;
    white-space: pre-line;
}

.cooperate {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 81px;
}

.qr {
    width: 392px;
    margin-left: 120px;
}

.cooperate>.text {
    max-width: 425px;
    margin-top: 80px;
    margin-right: 165px;
}

.cooperate>.text .title {
    line-height: 2em;
}

@media only screen and (max-width: 1037px) {
    .cooperate {
        justify-content: center;
    }
    .cooperate .text,
    .cooperate .qr {
        margin: 0;
    }
}

@media only screen and (max-width: 751px) {
    .cooperate {
        justify-content: center;
    }
    .cooperate .text,
    .cooperate .qr {
        margin: 0;
    }
    .cooperate .text {
        text-align: center;
    }
}

.silk {
    position: absolute;
    left: -24px;
    top: 0;
    width: 363px;
}

.silk-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 414px;
}
