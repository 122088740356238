.box-mission{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 506px;
    width: 100%;
    background-size: cover;
    background-image:url("/public/img-100kb/bg-mission.webp")
}

.mission-container{
    width: 1200px;
    min-height: 380px;
    /* background-color:rgba(255,255,255,0.5); */
    background:  linear-gradient(to  right,rgba(217, 240, 236,0.75),rgba(255,255,255,0.75));
    padding:95px 90px 105px 105px;
}
.mission-container p{
    margin-top: 30px;
}

@media only screen and (max-width: 1190px) {
    .mission-container {
      width: 100%;
    }
  }