.logo {
    width: 94px;
}


header {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    flex-wrap: nowrap;
}

.left {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    flex-wrap: nowrap;
    /* text-wrap: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bolder;
}
.right {
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.right nav {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.right li {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right li>a {
    height: 100%;
    padding-left: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.right li>a::after {
    height: 15px;
    width: 1px;
    content: "";
    background-color: #dbdbdb;
    margin-left: 26px;
}
.right li:last-child>a::after {
    height: 0;
    width: 0;
}
.right li>a:hover,
.right li>a:active,
.right li>a.selected {
    color: #02b2b5;
    cursor: pointer;
    border-bottom: 3px solid #02b2b5;
    border-top: 3px solid transparent;
}

.name {
    /* font-size: 20px; */
    color: rgb(34, 34, 34);
    font-family: 'Source Han Serif';
    margin-left: 30px;
}

.name p {
    line-height: calc(1em + 10px);
}

.btn-nav {
    width: 42px;
    height: 40px;
    position: fixed;
    right: 30px;
    background-size: contain;
    /* background-color: #02b2b5; */
    background-image: url("../../../public/navbtn.png");
    background-color: #ffffff;
    border-width: 0px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}


.right.small-screen nav {
    height: auto;
    background-color: #ffffff;
    position: fixed;
    top: 80px;
    right: 0;
    flex-direction: column;
}
.right.small-screen .text{
    margin-bottom: 10px;
    border-bottom: 2px solid;
    padding-bottom: 5px;
}

.right.small-screen ul {
    flex-direction: column;
    height: auto;
}
.right.small-screen li {
    height: auto;
}
.right.small-screen li>a {
    height: auto;
}
.right.small-screen li>a::after {
}
.right.small-screen li:last-child>a::after {
}
.right.small-screen li>a:hover,
.right.small-screen li>a:active,
.right.small-screen li>a.selected {
    color: #02b2b5;
    cursor: pointer;
    border-bottom: 0;
    border-top: 0;
}