.contact {
    background-color: #ffffff00;
}

.contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-header>.title {
    margin-bottom: 20px;
}

.contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.contacts .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 285px;
    height: 287px;
    background-color: #ffffff00;
}

.contacts .card .icon-wrap {
    width: 196px;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;

}
text{
    font-size: larger;
    margin-bottom: 10px;
    font-weight: bold;
}
.contacts .card .icon-wrap  {
    width: 196px;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon[alt="邮箱"] {
    width: 81px;
}
.icon[alt="电话"] {
    width: 49px;
}
.icon[alt="地址"] {
    width: 74px;
}
.contacts .card .title {
    margin-top: 20px;
}

.contacts .card .content {
    text-align: center;
}
