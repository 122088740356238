footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image:
        linear-gradient(to bottom, #d9f0ec 0, #ffffff00 150px),
        url("/public/img-100kb/footer-wy.webp");
    background-position: top center, center calc(100% + 81px);
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-top: 70px;
    padding-bottom: 96px;
}
p{
    align-items: center;
}