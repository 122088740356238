.bg {
    width: 100vw;
    min-height: 100vh;
    background-image: url("/public/img-500kb/bg-main.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.header-wrap {
    background-color: #ffffff;
    width: 100vw;
    height: 109px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

.wrap {
    width: 100vw;
    background-color: #ffffff;
}

.introduction-wrap {
    padding: 160px 0 125px;
}

.cooperate-wrap {
    
    padding: 0;
}

.contact-wrap {
    padding-top: 125px;
    background-image: linear-gradient(to top, #d9f0ec 0, #ffffff00 150px);
}

@media only screen and (max-width: 1190px) {
    .wrap {
        width: 100%;
    }
}
