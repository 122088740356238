body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 1190px) {
  .container {
    width: 100vw;
  }
  * {
    flex-wrap: wrap;
  }
}

@font-face {
  font-family: 'SiYuanSong';
  src: local('Source Han Serif CN'), local('Source Han Serif SC'),
    url('/public/font/SourceHanSerifCN-Medium-subset.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  color: #353535;
  font-family: 'SiYuanSong', SimSun, "Helvetica Neue", Arial, sans-serif;
  font-weight: bolder;
}
h1 {
  font-size: 2em;
}

p,
a {
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #000000;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}
P{
  line-height: 2em;
}
