.introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 32px;
}
.section-header>.title {
    margin-bottom: 36px;
}
.section-header>.subtitle {
    line-height: 2em;
}

.banners {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 80px;
}
.banners .banner {
    width: 220px;
    /* height: 218px; */
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 22px 0;
    margin: 32px;
}
.banners .banner .title {
    margin-bottom: 32px;
    font-size: 21px;
    line-height: 1em;
    color: #ffffff;
    font-family: 'Microsoft YaHei';
    border-bottom: 2px solid;
    padding-bottom: 8px;
}
.banners .banner .subtitle {
    margin-bottom: 45px;
    /* font-size: 14px; */
    line-height: 1.5em;
    color: #ffffff;
}
