.box-culture{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 110px 32px 110px;
    /* width: 100%; */
    min-height: 750px;
    
}

p{
    text-align: justify;
}
.box-culture .culture-h1{
    margin-bottom: 20px;
    /* font-size: 35px; */
    /* font-family: fontface__思源宋体medium__15__1620926246418; */
}
.box-culture .cultures{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.cuture-partbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    margin: 0 32px 32px;
    /* height: 200px; */
    /* background-color: aqua; */
}
.culture-image{
    width: 211px;
    height: 211px;
}
.cuture-partbox h1{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: 'Microsoft YaHei';
}
.cuture-partbox p{
    align-items: center;
}

@media only screen and (max-width: 912px) {
    .box-culture .cultures{
        justify-content: center;
    }
}
