.box-aboutus{
    
    width: 100vw;
    height: 630px;
    background-image:url("/public/img-100kb/bg-main.webp");
    background-size: cover;
    background-position: center;
    padding-top: 85px;
    padding: 50px 0;
}
.box-aboutus .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1151px) {
    .box-aboutus .container {
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 680px) {
    .box-aboutus .container {
        justify-content: center;
    }
}

.text-container{
    /* margin-left: 360px; */
    max-width: 680px;
	min-height: 530px;
	background-color: #ffffff;
    padding: 50px 45px;
}
.subcontainer{
    max-width: 590px;
	min-height: 430px;
}

.subcontainer h1{
    margin-left: 10px;
}
.about-p{
    margin-top: 30px;

}
.aboutus{
    margin-left: -10px;
    margin-bottom: -50px;
    font-style: italic;
    color: rgb(246, 246, 246);
    font-size: 69px;
    overflow-wrap: break-word;
    clear: both;
    /* font-family: 微软雅黑; */
}

.logo-white {
    width: 471px;
    height: auto;
    flex-shrink: 0;
}